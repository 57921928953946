const initialState = {
	countries: undefined,
	couriers: undefined
};

const customReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_ALL_COUNTRIES':
			return {
				...state,
				countries: action.data
			};
		case 'GET_COURIER_LIST':
			return {
				...state,
				couriers: action.data
			};
		default: {
			return state;
		}
	}
};
export default customReducer;
