import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	root: {
		'&.user': {
			'& .username, & .email': {
				transition: theme.transitions.create('opacity', {
					duration: theme.transitions.duration.shortest,
					easing: theme.transitions.easing.easeInOut
				})
			}
		}
	},
	avatar: {
		width: 72,
		height: 72,
		position: 'relative',
		padding: 8,
		// background: theme.palette.background.default,
		boxSizing: 'content-box',
		left: '50%',
		transform: 'translateX(-50%)',
		transition: theme.transitions.create('all', {
			duration: theme.transitions.duration.shortest,
			easing: theme.transitions.easing.easeInOut
		}),
		'& > img': {
			borderRadius: '50%'
		}
	},
	logoImage: {
		transition: theme.transitions.create('all', {
			duration: theme.transitions.duration.shortest,
			easing: theme.transitions.easing.easeInOut
		}),
		maxWidth: '12.8rem',
		maxHeight: '9.2rem',
		marginBottom: '1.6rem'
	}
}));

function UserNavbarHeader() {
	const user = useSelector(({ auth }) => auth.user);
	const classes = useStyles();

	return (
		<AppBar
			position="static"
			color="primary"
			elevation={0}
			classes={{ root: classes.root }}
			className="user relative flex flex-col items-center justify-center pt-24 z-0"
		>
			{/* <img
				alt="logo"
				className={clsx(classes.logoImage, 'logoImage')}
				src="/assets/images/logos/modmouth.png"
				title="Case Information"
			/> */}
			<Grid container className="items-center">
				{/* <Grid item xs={12}>
					<Divider variant="fullWidth" />{' '}
				</Grid> */}
				<Grid item xs={4}>
					<Avatar
						className={clsx(classes.avatar, 'avatar')}
						alt="user photo"
						src={user?.data?.photoURL || ''}
					/>
				</Grid>
				<Grid item xs={8}>
					<Typography className="username text-13 whitespace-no-wrap" color="inherit">
						{user.data.displayName}
					</Typography>
					<Typography className="email text-13 mt-8 opacity-50 whitespace-no-wrap" color="inherit">
						{user.data.email}
					</Typography>
				</Grid>
			</Grid>
		</AppBar>
	);
}

export default UserNavbarHeader;
